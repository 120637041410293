<template>
  <div class="settingPage" @keydown="returnFn($event)">
    <div
      class="back tabindex"
      tabindex="1"
      @keydown="keyDown($event, '/')"
      @click="goPath('/')"
    >
      Exit
    </div>
    <div>
      <ul class="settingList">
        <!-- <li tabindex="1" id='brightness' class="tabindex" @keydown="keyDown($event)">
          <span>brightness</span>
          <span class="add" @click="updateBrightness(true)">+</span>
          <span class="num">{{brightnessNum}}</span>
          <span class="sub" @click="updateBrightness(false)">-</span>
        </li> -->
        <li
          tabindex="1"
          class="tabindex"
          @keydown="keyDown($event, '/setting/wifi')"
          @click="goPath('/setting/wifi')"
        >
          <span> WLAN </span>
        </li>
        <li
          tabindex="1"
          class="tabindex"
          id="record"
          @keydown="keyDown($event)"
          @click="handle($event)"
        >
          <span> Recording </span>
          <span style="float: right;">
            <switchVue  :value="$store.state.recordState"/>
          </span>
        </li>
        <li
          tabindex="1"
          class="tabindex"
          @keydown="keyDown($event, '/setting/update')"
          @click="goPath('/setting/update')"
        >
          <span> Update </span>
          <span style="width: 45%"></span>
        </li>
        <li
          tabindex="1"
          class="tabindex"
          @keydown="keyDown($event, '/setting/service')"
          @click="goPath('/setting/service')"
        >
          <span> Terms of Service </span>
          <span style="width: 45%"></span>
        </li>
        <li
          tabindex="1"
          class="tabindex"
          @keydown="keyDown($event, '/setting/privacy')"
          @click="goPath('/setting/privacy')"
        >
          <span> Privacy </span>
          <span style="width: 45%"></span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { keyMap } from '@/util/keyUtil.js'
import switchVue from '../../components/switch.vue'
export default {
  components: { switchVue },
  data () {
    return {
      brightnessNum: 0,
      tableIndexDom: [],
      index: 1
    }
  },
  mounted () {
    this.tableIndexDom = document.querySelectorAll('.settingPage .tabindex')
    this.index = 1
    console.log(this.tableIndexDom)
    this.tableIndexDom[this.index].focus()
  },
  methods: {
    updateBrightness (flag) {
      if (flag) {
        this.brightnessNum++
        if (this.brightnessNum > 100) {
          this.brightnessNum = 100
        }
      } else {
        this.brightnessNum--
        if (this.brightnessNum < 0) {
          this.brightnessNum = 0
        }
      }
    },
    goPath (path) {
      this.$router.push(path)
    },
    handle (e) {
      this.index = Array.from(this.tableIndexDom).findIndex(item => document.getElementById('record') === item)
      if (this.$store.state.recordState) {
        this.$store.commit('updateRecordState', false)
        if (window.iBrowser) {
          this.$plugin.el.ipc_send('record_end')
        }
        console.log('turn off recording')
      } else {
        this.$store.commit('updateRecordState', true)
        if (window.iBrowser) {
          this.$plugin.el.ipc_send('record_begin')
        }
        console.log('turn on recording')
      }
    },
    keyDown (e, path) {
      const key = keyMap[e.keyCode]
      if (key === 'down' && this.index < this.tableIndexDom.length - 1) {
        this.index++
      } else if (key === 'up' && this.index > 0) {
        this.index--
      }
      this.tableIndexDom[this.index].focus()
      if (key === 'ok' && path) {
        this.$router.push(path)
      } else if (
        key === 'ok' &&
        e.target.id === 'record'
      ) {
        this.handle()
      }
    },
    returnFn (e) {
      const key = keyMap[e.keyCode]
      if (key === 'return') {
        this.$router.replace('/')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.settingPage {
  height: 100%;
  .back {
    display: block;
    width: 80px;
    font-size: 30px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    &:focus {
      background: gray;
    }
  }
  .settingList {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    height: 420px;
    li {
      border-radius: 10px;
      padding: 5px;
      width: 100%;
      line-height: 50px;
      color: #fff;
      font-size: 30px;
      margin-bottom: 5px;
      &:focus {
        background: gray;
      }
      &>span:nth-child(1) {
        display: inline-block;
        width: 50%;
        text-align: left;
      }
      &>span:nth-child(2) {
        display: inline-block;
        width: 15%;
        text-align: center;
      }
      &>span:nth-child(3) {
        display: inline-block;
        width: 10%;
        text-align: center;
      }
      &>span:nth-child(4) {
        display: inline-block;
        width: 15%;
        text-align: left;
        text-align: center;
      }
    }
  }
}
</style>
