<template>
  <div>
    <input type="checkbox" id="a" :checked="value"  @click="click">
    <label for="a"></label>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click (e) {
      e.preventDefault()
      e.stopPropagation()
      console.log('click:' + e.target.checked)
    }
  }
}
</script>
<style scoped>
input {
  display: none;
}

label {
  display: block;
  width: 100px;
  height: 40px;
  border-radius: 30px;
  background: rgb(128, 128, 128);
  border: 1px solid #eee;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

label::before {
  display: block;
  content: '';
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -webkit-transition: all .3s;
}

label::after {
  display: block;
  content: '';
  width: 0;
  height: 100%;
  background: #006BB0;
  transition: all .3s;
  border-radius: 10px;
}

input:checked+label::before {
  left: 60px;
}

input:checked+label::after {
  width: 100%;
}
</style>
